<template>
  <div id="mian">
    <!-- 上面表单框 -->
    <div class="con_from">
      <div class="lineP">
        <div class="item">
          <i>店铺名称</i>
          <el-input
            v-model="query.keyword"
            maxlength="128"
            suffix-icon="el-icon-search"
            placeholder="店铺名称/店铺编号"
          ></el-input>
        </div>
        <div class="item">
          <i>所属商户</i>
          <el-select
            v-model="query.mchId"
            placeholder="全部"
          >
            <el-option label="全部" value=""></el-option>
            <el-option
              v-for="v in mchDrop"
              :key="v.mchId"
              :label="v.mchName"
              :value="v.mchId"
            >
            </el-option>
          </el-select>
        </div>
        <div class="item">
          <i>进件状态</i>
          <el-select
            v-model="query.applyStatus"
            placeholder="全部"
          >
            <el-option label="全部" value=""></el-option>
            <el-option label="进件中" value="APPLYING"></el-option>
            <el-option label="已完成" value="FINISHED"></el-option>
            <el-option label="进件失败" value="FAILED"></el-option>
          </el-select>
        </div>
      </div>
      <div class="lineP">
        <div class="item">
          <i>冻结状态</i>
          <el-select
            v-model="query.frozenStatus"
            placeholder="全部"
          >
            <el-option label="全部" value=""></el-option>
            <el-option label="冻结" value="FROZENED"></el-option>
            <el-option label="解冻" value="UNFROZEN"></el-option>
          </el-select>
        </div>
        <div class="item itemTime">
          <i>创建时间</i>
          <el-date-picker
            v-model="query.startTime"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            placeholder="选择时间"
          >
          </el-date-picker>
          <span style="padding-left: 10px; padding-right: 10px">-</span>
          <el-date-picker
            v-model="query.endTime"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            placeholder="选择时间"
          >
          </el-date-picker>
        </div>
        <div class="itembtn" style="width: 10%;">
          <el-button type="primary" class="searchBt" @click="getLists"
            >查询</el-button
          >
        </div>
      </div>
    </div>
    <div class="tab1">
      <table class="tab">
        <tr class="tab_title">
          <td>
            <p>创建时间</p>
          </td>
          <td>
            <p>店铺名称</p>
          </td>
          <td>
            <p>店铺编号</p>
          </td>
          <td>
            <p>所属商户</p>
          </td>
          <td>
            <p>进件状态</p>
          </td>
          <td>
            <p>冻结状态</p>
          </td>
          <td>
            <p>错误码</p>
          </td>
          <td>
            <p>错误描述</p>
          </td>
        </tr>
        <tr v-for="v in tabData" :key="v.storeId">
          <td>
            <p>{{ v.createTime }}</p>
          </td>
          <td>
            <p>{{ v.storeName }}</p>
          </td>
          <td>
            <p>{{ v.storeId }}</p>
          </td>
          <td>
            <p>{{ v.mchName }}</p>
          </td>
          <td>
            <p>
              {{
                v.applyStatus == "APPLYING"
                  ? "进件中"
                  : v.applyStatus == "FINISHED"
                  ? "已完成"
                  : v.applyStatus == "FAILED"
                  ? "进件失败"
                  : ""
              }}
            </p>
          </td>
          <td>
            <p>{{ frozenStatus[v.frozenStatus] }}</p>
          </td>
          <td>
            <p>{{ v.errorCode }}</p>
          </td>
          <td>
            <p>{{ v.errorMessage }}</p>
          </td>
        </tr>
      </table>
    </div>
    <el-pagination
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="query.pageNo"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="query.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="totalNum"
    >
    </el-pagination>
  </div>
</template>
<script>
import { getQueues } from "@/api/user/shopList.js";
import { merchantsDrop } from "@/api/common.js";
import { mapState, mapMutations } from "vuex";
export default {
  data() {
    return {
      query: {
        pageNo: 1,
        pageSize: 10,
        mchId: "",
        startTime: "",
        endTime: "",
        applyStatus: "",
        frozenStatus: "",
        keyword: "",
      },
      totalNum: 0,
      tabData: [],
      mchDrop: [], //商户下拉
      value1: "",
      value2: "",
      value: true,
      queuselist: [],
      frozenStatus: {
        FROZENED: "冻结",
        UNFROZEN: "解冻",
      },
    };
  },
  computed: {
    ...mapState({
      shopIntoQueryDetail: "user_shopIntoQuery",
    }),
  },
  created() {
    if (this.shopIntoQueryDetail) {
      this.query = this.shopIntoQueryDetail.query;
    }
    this.getDrop();
    this.getLists(false);
    this.$enter(this.$route.path, this.getLists);
  },
  methods: {
    ...mapMutations({
      setShopIntoQuery: "user_setShopIntoQuery",
    }),
    // 获取下拉菜单
    getDrop() {
      merchantsDrop().then((res) => {
        if (res) {
          this.mchDrop = res.resultData;
        }
      });
    },
    // 获取详情
    getLists(reset = true) {
      if (reset) {
        this.query.pageNo = 1;
      }
      this.query.startTime = this.query.startTime ? this.query.startTime : "";
      this.query.endTime = this.query.endTime ? this.query.endTime : "";
      this.query.keyword = this.query.keyword.trim();

      getQueues(this.query).then((res) => {
        if (res.resultStatus) {
          this.tabData = res.resultData.stores;
          if (this.tabData.length == 0) {
            this.$message({
              message: "暂无数据",
              duration: 1500,
            });
          }
          this.totalNum = res.resultData.totalNum;
        }
      });
    },
    // 页面大小改变
    handleSizeChange(size) {
      this.query.pageSize = size;
      this.getLists();
    },
    handleCurrentChange(page) {
      this.query.pageNo = page;
      this.getLists(false);
    },
  },
  beforeDestroy() {
    let data = {
      query: this.query,
    };
    this.setShopIntoQuery(data);
  },
};
</script>
<style scoped>

</style>
